<template>
  <b-card
    v-if="isCustomFreeLikeMessage"
    :key="key"
    :header="$t('fCustomer_crdCustomFreeLikeMessage_title')"
    header-tag="h4"
    class="text-center"
    :bg-variant="customFreeLikeMessage.is_valid ? '' : 'warning'"
  >
    <div class="d-flex flex-column justify-content-between h-100">
      <blockquote
        v-if="!editState"
        class="card-title pb-2 blockquote"
      >
        <span v-shtml="customFreeLikeMessage.message" />
        <a @click="onEdit" class=" ml-2 text-info">
          <font-awesome-icon icon="pen" size="sm" />
        </a>
      </blockquote>
      <div v-else class="d-flex">
        <b-form-textarea v-model="message" no-resize rows="4" />
        <div class="ml-2 d-flex flex-column">
          <a @click="onEditCancel" class="text-danger">
            <font-awesome-icon icon="xmark" size="sm" />
          </a>
          <a @click="onUpdate" class="text-success">
            <font-awesome-icon icon="check" size="sm" />
          </a>
        </div>
      </div>
      <div
        v-if="!customFreeLikeMessage.is_valid && !editState"
        class="d-flex align-items-center justify-content-center"
      >
        <b-btn
          size="sm"
          class="mx-2"
          variant="success"
          @click="onApprove"
        >
          {{ $t('fCustomer_crdCustomFreeLikeMessage_approve') }}
        </b-btn>
        <b-btn
          size="sm"
          class="mx-2"
          variant="warning"
          @click="onDecline"
        >
          {{ $t('fCustomer_crdCustomFreeLikeMessage_decline') }}
        </b-btn>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardCustomFreeLikeMessage',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editState: false,
      message: ''
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'info'
    ]),
    isCustomFreeLikeMessage () {
      return !!this.info && this.info.custom_freelike_message
    },
    customFreeLikeMessage () {
      return this.info && this.info.custom_freelike_message
    }
  },
  watch: {
    info: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    onApprove () {
      this.cashoutState = false
      this.$emit('on-approve')
    },
    onDecline () {
      this.creditsState = false
      this.$emit('on-decline')
    },
    onEdit () {
      this.message = this.customFreeLikeMessage.message
      this.editState = true
    },
    onEditCancel () {
      this.message = ''
      this.editState = false
    },
    onUpdate () {
      this.editState = false
      this.$emit('on-update', this.message)
      this.message = ''
    }
  }
}
</script>

<style scoped>

</style>
