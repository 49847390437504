<template>
  <b-card
    :key="key"
    :header="$t('fCustomer_crdBoost_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ boost && boost.remaining }}</span>
      <small class="text-muted">{{ $t('fCustomer_crdBoost_amount') }}</small>
    </h1>
    <ul class="list-unstyled mt-3 mb-3 text-muted">
      <li>{{ boost && boost.purchased }} {{ $t('fCustomer_crdBoost_purchased') }}</li>
      <li>{{ boost && boost.bonus }} {{ $t('fCustomer_crdBoost_bonus') }}</li>
    </ul>
    <div>
      <b-form-group
        v-if="boostState && !isLocked"
        id="manage-bonuses"
        :label="$t('fCustomer_crdBoost_makeBoost')"
        label-for="form-input-manage-bonuses"
        class="pt-2 pb-2"
      >
        <b-input-group>
          <b-form-select
            id="form-input-manage-bonuses"
            ref="boost"
            v-model="addBoostPackageId"
            :options="boostPackages"
            type="number"
            size="sm"
            @input="onChange('boost')"
          />
          <b-input-group-append>
            <b-button
              :variant="addBoostPackageId > 0 ? 'outline-success' : 'outline-secondary'"
              :disabled="parseInt(addBoostPackageId) === 0"
              size="sm"
            >
              <font-awesome-icon :icon="['fas', addBoostPackageId > 0 ? 'check' : 'circle-question']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <!--
        <small class="d-block">or set custom value</small>
        <b-input-group>
          <b-form-input
            id="form-input-manage-bonuses"
            ref="boost"
            v-model="addBoost"
            type="number"
            size="sm"
            min="0"
            @input="onChange('boost')"
          />
          <b-input-group-append>
            <b-button
              :variant="addBoost > 0 ? 'outline-success' : 'outline-secondary'"
              :disabled="parseInt(addBoost) === 0"
              size="sm"
            >
              <font-awesome-icon :icon="['fas', addBoost > 0 ? 'check' : 'circle-question']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        -->
        <b-form-group class="mt-2">
          <b-button
            :variant="addBoostPackageId >= 0 ? 'outline-success' : 'outline-secondary'"
            :disabled="parseInt(addBoostPackageId) === 0"
            size="sm"
            @click="onManageBoost"
          >
            {{ $t('fCustomer_crdBoost_apply') }}
          </b-button>
          <b-button
            :variant="'outline-danger'"
            size="sm"
            @click="toggleBoostForm"
          >
            {{ $t('fCustomer_crdBoost_cancel') }}
          </b-button>
        </b-form-group>
      </b-form-group>
      <b-btn
        v-else
        variant="primary"
        size="sm"
        class="mb-2"
        :disabled="isLocked"
        @click="toggleBoostForm"
      >
        {{ $t('fCustomer_crdBoost_makeBoost') }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardBoost',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    formData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      boostState: false
    }
  },
  computed: {
    ...mapComputedFormFields('customerEdit', [
      // 'addBoost',
      'addBoostPackageId'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'type',
      'editColumnName',
      'editColumnCursor',
      'info'
    ]),
    sex () {
      return this.info && this.info.profile && this.info.profile.sex
    },
    boost () {
      return this.info && this.info.boost
    },
    awailableBoost () {
      return this.boost
        ? this.boost.amount
        : 0
    },
    boostPackages () {
      return [
        {
          value: 0,
          text: this.$t('fCustomer_crdBoost_choosePackage')
        },
        ...(this.formData?.boostPackages || [])
      ]
    }
  },
  watch: {
    form: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    },
    info: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    setInputState () {
      const cols = [
        // 'addBoost',
        'addBoostPackageId'
      ]
      if (this.editColumnName === 'bonusCredits') {
        this.bonusesState = true
        this.$forceUpdate()
      }
      this.$nextTick(() => {
        if (cols.includes(this.editColumnName) && this.$refs[this.editColumnName]) {
          this.$refs[this.editColumnName].$el.focus()
          if (this.$refs[this.editColumnName].$el.setSelectionRange && this.editColumnCursor >= 0) {
            this.$refs[this.editColumnName].$el.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
          }
        }
      })
    },
    onChange (colName) {
      this.editColumnName = colName
      if (this.$refs[colName]) {
        const column = this.$refs[colName]
        this.editColumnCursor = (column && column.$el.selectionStart) || -1
      }
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    isDirty (fieldKey) {
      const dirtyFields = this.dirty && Object.keys(this.dirty)
      return !!(dirtyFields && dirtyFields.length && dirtyFields.includes(fieldKey))
    },
    toggleBoostForm () {
      this.boostState = !this.boostState
      this.boost = this.boostState ? this.awailableBoost * -1 : 0
    },
    onManageBoost () {
      this.boostState = false
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
