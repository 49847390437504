<template>
  <b-card
    :header="$t('fCustomer_crdChat_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ talksInfo && talksInfo.tot }}</span>
      <small class="text-muted">
        {{ $t('fCustomer_crdChat_talks') }}
      </small>
    </h1>
    <ul class="list-unstyled mt-3 mb-3 text-muted">
      <li>
        {{ $t('fCustomer_crdChat_unlock', { unlocked: talksInfo && talksInfo.unlocked }) }}
      </li>
      <li>
        {{ $t('fCustomer_crdChat_monetise', { paid: talksInfo && talksInfo.paid }) }}
      </li>
      <li
        v-shtml="$t('fCustomer_crdChat_messSent', {
          sent: talksInfo && talksInfo.sent,
          freeSent: talksInfo && talksInfo.freeSent
        })"
      />
      <li
        v-shtml="$t('fCustomer_crdChat_messReceived', {
          received: talksInfo && talksInfo.received,
          freeReceived: talksInfo && talksInfo.freeReceived
        })"
      />
    </ul>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdChat_viewTalks') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'
export default {
  name: 'CardChat',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    talksInfo () {
      return this.info && this.info.talks
    }
  }
}
</script>

<style scoped>

</style>
