<template>
  <b-card
    :header="$t('fCustomer_crdChatSup_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ supportTalkInfo && supportTalkInfo.tot }}</span>
      <small class="text-muted">
        {{ $t('fCustomer_crdChatSup_messages') }}
      </small>
    </h1>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdChatSup_viewChat') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'
export default {
  name: 'CardChatSupport',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    supportTalkInfo () {
      return this.info && this.info.support
    }
  }
}
</script>

<style scoped>

</style>
