<template>
  <b-card
    :header="$t('fCustomer_crdCred_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ credits.tot ? credits.tot : 0 }}</span>
      <small class="text-muted">
        {{ $t('fCustomer_crdCred_credits') }}
      </small>
    </h1>
    <ul
      v-if="sex === 'M'"
      class="list-unstyled mt-3 mb-3 text-muted"
    >
      <li>{{ credits && credits.bonusSystem }} {{ $t('fCustomer_crdCred_bonusSystem') }}</li>
      <li>{{ credits && credits.bonusStaff }} {{ $t('fCustomer_crdCred_staffBonuses') }}</li>
      <li>{{ credits && credits.purchased }} {{ $t('fCustomer_crdCred_purchased') }}</li>
      <li>{{ credits && credits.talk }} {{ $t('fCustomer_crdCred_spentInConversations') }}</li>
      <li>{{ credits && credits.gift }} {{ $t('fCustomer_crdCred_givenAway') }}</li>
    </ul>
    <ul
      v-else-if="sex === 'F'"
      class="list-unstyled mt-3 mb-3 text-muted"
    >
      <li>{{ credits && credits.gift }} {{ $t('fCustomer_crdCred_receivedAsGift') }}</li>
      <li>{{ credits && credits.featuredPurchased }} {{ $t('fCustomer_crdCred_purchasedForFeatured') }}</li>
      <li>{{ credits && credits.featuredConverted }} {{ $t('fCustomer_crdCred_convertedToFeatured') }}</li>
      <li>{{ credits && credits.cashout }} {{ $t('fCustomer_crdCred_cashout') }}</li>
    </ul>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdCred_viewHistory') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'
export default {
  name: 'CardCredits',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    sex () {
      return this.info && this.info.profile && this.info.profile.sex
    },
    credits () {
      return this.info && this.info.credits
    }
  }
}
</script>

<style scoped>

</style>
