<template>
  <b-card
    :header="$t('fCustomer_crdFeedb_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ totalCount }}</span>
      <small class="text-muted">
        {{ $t('fCustomer_crdFeedb_item') }}
      </small>
    </h1>
    <ul
      class="list-unstyled mt-3 mb-3 text-muted"
    >
      <li>{{ sentCount }} {{ $t('fCustomer_crdFeedb_sentTitle') }}</li>
      <li>{{ receivedCount }} {{ $t('fCustomer_crdFeedb_receivedTitle') }}</li>
      <li>{{ readyCount }} {{ $t('fCustomer_crdFeedb_readyToSentTitle') }}</li>
    </ul>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdFeedb_viewItem') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'
export default {
  name: 'CardFeedbacks',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    totalCount () {
      return (this.info && this.info.feedbacks && this.info.feedbacks.tot) || 0
    },
    sentCount () {
      return (this.info && this.info.feedbacks && this.info.feedbacks.sent) || 0
    },
    receivedCount () {
      return (this.info && this.info.feedbacks && this.info.feedbacks.received) || 0
    },
    readyCount () {
      return (this.info && this.info.feedbacks && this.info.feedbacks.ready_to_sent) || 0
    }
  }
}
</script>

<style scoped>

</style>
